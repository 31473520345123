cs.ns('app.ui.widget.list')
app.ui.widget.list.ctrl = cs.clazz({
    extend:   app.fw.abstract.ctrl,
    dynamics: {},
    protos:   {

        create () {
            this.base(app.ui.widget.list.model, app.ui.widget.list.view)
            cs(this).property('ComponentJS:state-auto-increase', true)

            //
            //    S E R V I C E   M E T H O D S
            //
            this.registerAPI('resetListEntries', () => {
                this.model.value('data:listEntries', [])
            })

            this.registerAPI('addListEntry', (elem) => {
                let data = this.model.value('data:listEntries')
                if (!this.listContainsElement(data, elem)) {
                    data.push(elem)
                    this.model.touch('data:listEntries')
                }
            })

            this.registerAPI('selectedEntry', (id) => {
                this.model.value('data:selectedEntry', id)
            })
        },

        prepare () {
            this.observeOwnModel('event:selectEntry', (ev, clickId) => {
                this.publishEventToParent('list:selectedEntry', clickId)
            })
        },

        listContainsElement (list, elem) {
            return _.find(list, { id: elem.id })
        }

    }
})